import React from "react";

import { Layout } from "../../components/shared/layout";
import { Helmet } from "../../components/shared/helmet";
import { PageTitle } from "../../components/shared/page-title";

import { Section } from "../../components/shared/section";
import { ButtonLink } from "../../components/shared/button-link";
import { FullHeight } from "../../components/shared/full-height";

export default () => (
  <Layout>
    <Helmet title="Speisekarten" />
    <PageTitle>SPEISEKARTEN</PageTitle>
    <FullHeight inverted>
      <Section>
        <ButtonLink to="/speisekarten/petit-cafe">Petit Café</ButtonLink>
        <ButtonLink to="/speisekarten/cafe-paris">Kultur Café Paris</ButtonLink>
      </Section>
    </FullHeight>
  </Layout>
);
