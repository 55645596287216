import React from "react";
import cn from "classnames";

import styles from "./full-height.module.scss";

export const FullHeight: React.FC<{ inverted?: boolean }> = ({
  children,
  inverted,
}) => (
  <div className={cn(styles.wrapper, inverted && styles.inverted)}>
    {children}
  </div>
);
